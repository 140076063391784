


<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import { Modal } from "bootstrap";
import GoogleMap from "../maps/GoogleMap.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    GoogleMap,
  },
  data() {
    return {
      markers: [
        // Array of markers for multiple markers
        { position: { lat: 37.7749, lng: -122.4194 } }, // San Francisco coordinates
        { position: { lat: 34.0522, lng: -118.2437 } }, // Los Angeles coordinates
        // Add more markers as needed
      ],
      map_modal: "",
      apiKey: "AIzaSyChzOQofZVtnlA_g-ekWp5CLnUt2m7yUrw",
      zoom: 10,
      center: { lat: 37.7749, lng: -122.4194 }, // Default center coordinates (San Francisco)

      notfication_settings: [],
      orders: [],
      user_details: [],
      categories: [],
      rate: [],
      coupons: [],
      branches: [],
      page: 1,
      vendor_id: 0,
      limit: 20,
      tot_pages: 0,
      state: state,
    };
  },
  methods: {
    changeStates(app) {
      console.log("Iam working ");
      let status = 0;
      if (app.status) {
        status = 0;
      }
      if (!app.status) {
        status = 1;
      }
      let data = { id: this.vendor_id, status: status };
      this.http.post("users/update-status", data).then((res) => {
        if (res.status) {
          this.get(1);
        }
      });
    },

    get(page) {
      console.log(page);
      // let id = this.id
      this.http.get("users/vendors/details/" + this.vendor_id).then((res) => {
        this.tot_pages = Math.ceil(res.tot / this.limit);
        this.user_details = res.data.user_details[0];
        this.orders = res.data.orders;
        this.categories = res.data.cateogries;
        this.coupons = res.data.coupons;
        this.rate = res.data.rate;
        this.branches = res?.data?.branches;
      });
    },
    openMapModal(data) {
      console.log("hi");
      console.log(data);
      this.map_modal.show();
    },
  },
  mounted() {
    this.map_modal = new Modal("#map_modal");
  },
  created() {
    // this.map_modal= Modal('#map_modal')
    this.vendor_id = this.$route.params.id;
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.vendors.text')"
    />
    <div class="row">
      <div class="col-8">
        <b-tabs
          content-class="p-4"
          class="pt-2"
          nav-wrapper-class="nav-item"
          nav-class="justify-content-start nav-tabs-custom"
        >
          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.branches.text')" active>
            <div class="row">
              <b-tabs
                content-class=""
                class=""
                style="width: 200%"
                nav-wrapper-class="nav-item"
                nav-class="justify-content-start nav-tabs-custom"
              >
                <b-tab :title="$t('menu.menuitems.table_view.text')" active>
                  <table
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    class="table mt-1"
                  >
                    <thead>
                      <tr
                        class="text-light text-center"
                        style="background-color: #2a3042 !important"
                      >
                        <th scope="col">{{ $t("branches.id") }}</th>
                        <th scope="col">{{ $t("branches.name") }}</th>
                        <th scope="col">{{ $t("branches.address") }}</th>
                        <th scope="col">{{ $t("branches.phone") }}</th>
                        <th scope="col">{{ $t("branches.created") }}</th>
                        <th scope="col">{{ $t("branches.updated") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(app, i) in branches"
                        :key="app"
                        class="text-center"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ app?.name }}</td>
                        <td>{{ app?.address }}</td>
                        <td>{{ app?.whatsapp }}</td>
                        <td>{{ app?.created?.split("T")[0] }}</td>
                        <td>{{ app?.updated?.split("T")[0] }}</td>
                        <!-- <td><img :src="$store.state?.auth?.mediaUrl + app?.image.split('public')[1]"
                                        style="width: 64px;height: 64px;" alt=""></td>
                                <td>{{ app?.created?.split('T')[0] }}</td>
                                <td>{{ app?.updated?.split('T')[0] }}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </b-tab>
                <b-tab
                  style="width: 200%"
                  :title="$t('menu.menuitems.map_view.text')"
                >
                  <div style="width: 200%">
                    <google-map style="width: 200%" :markers="markers" />
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>

          <b-tab :title="$t('menu.menuitems.categories.text')">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table mt-1"
            >
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("cateogries.id") }}</th>
                  <th scope="col">{{ $t("cateogries.name") }}</th>
                  <th scope="col">{{ $t("cateogries.image") }}</th>
                  <th scope="col">{{ $t("cateogries.created") }}</th>
                  <th scope="col">{{ $t("cateogries.updated") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(app, i) in categories"
                  :key="app"
                  class="text-center"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ app?.name }}</td>
                  <td>
                    <img
                      :src="
                        $store.state?.auth?.mediaUrl +
                        app?.image?.split('public')[1]
                      "
                      style="width: 64px; height: 64px"
                      alt=""
                    />
                  </td>
                  <td>{{ app?.created?.split("T")[0] }}</td>
                  <td>{{ app?.updated?.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>

          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.orders.text')">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table mt-1"
            >
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("orders.id") }}</th>
                  <th scope="col">{{ $t("orders.custmor_id") }}</th>
                  <th scope="col">{{ $t("orders.location") }}</th>
                  <th scope="col">{{ $t("orders.total") }}</th>
                  <th scope="col">{{ $t("orders.status_id") }}</th>
                  <th scope="col">{{ $t("orders.created") }}</th>
                  <th scope="col">{{ $t("orders.updated") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="app in orders" :key="app" class="text-center">
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">
                      # {{ app?.order?.id }}</router-link
                    >
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.order.id">
                      {{ app?.order?.user?.name }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.order.id">
                      {{ app?.order.user_address?.address }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.order.id">
                      {{
                        parseInt(app?.order.order_price) -
                        parseInt(app?.order.discount_price) +
                        parseInt(app?.order.tip)
                      }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.order.id">
                      {{ app?.order.status?.status_ar }}
                    </router-link>
                  </td>
                  <td>{{ app?.order.created.split("T")[0] }}</td>
                  <td>{{ app?.order.updated.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>
          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.rate.text')">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table mt-1"
            >
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("orders.id") }}</th>
                  <th scope="col">{{ $t("popups.comment") }}</th>
                  <th scope="col">{{ $t("popups.points") }}</th>
                  <th scope="col">{{ $t("orders.user") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, i) in rate" :key="app" class="text-center">
                  <td>{{ i + 1 }}</td>
                  <td>{{ app?.comment }}</td>
                  <td>{{ app?.points }}</td>
                  <td>{{ app?.user.name }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>

          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.coupons.text')">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table mt-1"
            >
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("coupons.id") }}</th>
                  <th scope="col">{{ $t("coupons.name") }}</th>
                  <th scope="col">{{ $t("coupons.end_date") }}</th>
                  <th scope="col">{{ $t("coupons.created") }}</th>
                  <th scope="col">{{ $t("coupons.updated") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, i) in coupons" :key="app" class="text-center">
                  <td>{{ i + 1 }}</td>
                  <td>{{ app?.name }}</td>
                  <td>{{ app?.end_date?.split("T")[0] }}</td>
                  <td>{{ app?.created?.split("T")[0] }}</td>
                  <td>{{ app?.updated?.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col">
        <div class="row mt-2">
          <div class="d-print-none">
            <div class="float-end">
              <a
                href="javascript:window.print()"
                class="btn btn-success mx-1 waves-effect waves-light me-1"
                ><i class="fa fa-print"></i
              ></a>
              <a
                v-if="user_details?.status"
                href="#"
                @click="changeStates(user_details)"
                class="btn btn-primary w-md waves-effect waves-light"
                >{{ $t("popups.active") }}</a
              >
              <a
                v-if="!user_details?.status"
                href="#"
                @click="changeStates(user_details)"
                class="btn btn-warning w-md mx-1 waves-effect waves-light"
                >{{ $t("popups.enactive") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="map_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog" style="max-width: 1024px">
        <div class="modal-content">
          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.order_tracking") }}
            </h5>
            <button
              type="button"
              class="btn-close-add"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            >
              X
            </button>
          </div>

          <div class="modal-body px-0">
            <div class="row">
              <div class="col">
                <div id="app">
                  <div>
                    <google-map :markers="markersData" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




