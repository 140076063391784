<template>
  <GoogleMap :center="center" :zoom="zoom" >
    <!-- Single Marker -->

    <!-- Multiple Markers using v-for -->
    <Marker
      v-for="(marker, index) in markers"
      :key="index"
      :position="marker.position"
      :icon="'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png'"
    />
  </GoogleMap>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  components: {
    GoogleMap,
    Marker,
  },

  props: {
    markers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      center: { lat: 37.7749, lng: -122.4194 }, // San Francisco coordinates
      zoom: 10,
      markerPosition: { lat: 37.7749, lng: -122.4194 }, // Marker position for single marker

      mapOptions: {
        // Define map options
        mapTypeId: "roadmap", // Example map type
        // Add more options as needed
      },
    };
  },
};
</script>
